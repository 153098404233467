import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Typography from '../Typography'
import { Box } from '@material-ui/core'
import Button from '../Button'
import Image from '../Image'

const CareerTeaser = props => {
  const query = graphql`
    query {
      images: allFile(
        filter: {
          relativePath: {
            in: ["karriere_stoerer_01.jpg", "karriere_stoerer_02.jpg"]
          }
        }
        sort: { fields: relativePath }
      ) {
        nodes {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={({ images: { nodes: images } }) => (
        <>
          <Image fluid={images[0].childImageSharp.fluid} />
          <Box mx="auto" my={5} maxWidth="450px">
            <Typography color="primary" as="div" variant="careerTeaserLabel">
              Karriere bei Hahn Helten Architektur
            </Typography>
            <Typography color="primary" as="div" variant="careerTeaser">
              Raum für Entfaltung mit Empathie und Prozessen.
            </Typography>
            <Box mt={2}>
              <Button to="/karriere">Zur Karriereseite</Button>
            </Box>
          </Box>
          <Image fluid={images[1].childImageSharp.fluid} />
        </>
      )}
    ></StaticQuery>
  )
}

CareerTeaser.propTypes = {
  //
}

CareerTeaser.defaultProps = {
  //
}

export default CareerTeaser
