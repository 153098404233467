import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box } from '@material-ui/core'
import Image from '../Image/Image'
import Typography from '../Typography/Typography'

const Styled = styled(Box)``

const Avatar = styled(Image)`
  width: ${props => props.imgSize}px;
`

const Label = styled.div`
  margin-left: ${({ theme }) => theme.spacing(20 / 8, 'px')};
  color: ${({ color, theme }) =>
    color === 'primary' ? theme.palette.primary.main : 'inherit'};
`

const Person = props => {
  const { className, name, position, imgSrc, imgSize, imgFluid, color } = props

  return (
    <Styled display="flex" alignitems="center" {...{ className }}>
      <Avatar src={imgSrc} fluid={imgFluid} {...{ imgSize }} />
      <Label {...{ color }}>
        <Typography variant="personName">{name}</Typography>
        <br />
        <Typography variant="personPos">{position}</Typography>
      </Label>
    </Styled>
  )
}

Person.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  imgSrc: PropTypes.array,
  imgSize: PropTypes.number,
  imgFluid: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary']),
}

Person.defaultProps = {
  color: 'secondary',
  imgSize: 80,
}

export default Person
