import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../layout'
import Hero from 'src/components/Hero'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Section from 'src/components/Section'
import Container from 'src/components/Container'
import KirbyContent from 'src/components/KirbyContent'
import Typography from 'src/components/Typography'
import Person from 'src/components/Person'
import { Box } from '@material-ui/core'
import GradientOverlay from 'src/components/GradientOverlay'
import CareerTeaser from 'src/components/CareerTeaser'
import get from 'lodash/get'

const StyledKirbyContent = styled(KirbyContent)`
  margin-top: 50px;
`

export default function Focus(props) {
  const {
    pages: { nodes: pages },
    jobs: { nodes: jobs },
  } = props.data

  const {
    title,
    label,
    intro,
    text,
    metaTitle,
    metaDescription,
    contact,
  } = jobs[0]

  const { contactTitle, contactText } = pages[0]

  const contactProps = {
    contactTitle,
    contactText,
    contact,
  }

  const metaProps = {
    metaTitle,
    metaDescription,
  }

  return (
    <Layout contact={contactProps} meta={metaProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero>
          <GradientOverlay color="bottomLeft" test="test" />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="heroLabel" paragraph>
              {label}
            </Typography>
            <Typography variant="hero" paragraph>
              {title}
            </Typography>
          </HeroContent>
        </Hero>
      </Section>

      {/*
            Content
      */}
      <Section color="transparent" border={false} flush>
        <Container>
          <Box mx="auto" maxWidth={720}>
            <Typography variant="h2" paragraph>
              {intro}
            </Typography>
            <StyledKirbyContent content={text} />
          </Box>
          <Box mx="auto" mt={10} maxWidth={720}>
            <CareerTeaser />
          </Box>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    jobs: allJobs(filter: { id: { eq: $id } }) {
      nodes {
        slug
        title
        label
        intro
        created: created_at(formatString: "DD.MM.YYYY")
        text {
          content
          type
        }
        contact {
          ...ContactFragment
        }
        metaTitle
        metaDescription
      }
    }

    pages: allPages(filter: { id__normalized: { eq: "karriere" } }) {
      nodes {
        contactTitle
        contactText
      }
    }
  }
`

// text {
//   content
//   type
//   local {
//     childImageSharp {
//       fluid(maxWidth: 490, quality: 60) {
//         ...GatsbyImageSharpFluid_noBase64
//       }
//     }
//   }
// }
